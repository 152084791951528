import React from 'react';
import { Typography, Row, Col, Avatar } from 'antd';

const { Title, Paragraph } = Typography;

const AboutMe = () => {
  return (
    <Row id="home" type="flex" justify="center" align="middle" style={{ marginTop: 0, minHeight: '100vh', padding: 8 }}>
      <Col xs={24} sm={24} md={22} lg={20} xl={18}>
        <Avatar
          size={150}
          src="./my_photo.jpg"
          style={{
            marginBottom: 10,
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            width: '150px',
            height: '150px',
            backgroundColor: '#808080',
          }}
        />
        <Title level={3} style={{ textAlign: 'center', marginTop: 20, color: '#1E1E1E' }}>Hello World</Title>
        <Paragraph style={{ fontSize: '1rem', textAlign: 'center', color: '#808080' }}>
          I'm Siraj and I'm here to save the day! 
        </Paragraph>
      </Col>
    </Row>
  );
};

export default AboutMe