import React, { useState, useEffect } from 'react';
import { Layout, Menu, Typography, Grid } from 'antd';
import { scroller } from 'react-scroll';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const Header = () => {
  const menuItems = [
    { id: 'home', label: 'Home', key: 'home' },
    { id: 'superpowers', label: 'Superpowers', key: 'superpowers' },
    { id: 'secretweapons', label: 'Secretweapons', key: 'secretweapons' },
    { id: 'adventures', label: 'Adventures', key: 'adventures' },
    { id: 'contact', label: 'Contact', key: 'contact' },
  ];

  const screens = useBreakpoint();
  const [selected, setSelected] = useState('home');

  const handleClick = (id) => {
    setSelected(id);
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: !(screens.md) ? -56 : 0 ,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPos = window.scrollY;
      menuItems.forEach((item) => {
        const section = document.getElementById(item.id);
        if (section && section.offsetTop <= scrollPos) {
          setSelected(item.id);
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [menuItems]);

  return (
    <Layout.Header style={{ position: 'fixed', width: '100%', zIndex: 1, top: 0,
                            background: '#fff', display: 'flex', flex: 1, justifyContent: 'space-between' }}>
      <div style={{paddingTop:8}}>
              <Text style={{ fontSize: '22px'}}>
        &nbsp;&nbsp;Siraj.AI&nbsp;&nbsp;
      </Text>
      </div>
      <Menu
        selectedKeys={[selected]}
        mode="horizontal" 
        style={{ width: '50%', justifyContent: 'end' }}
        >
        {menuItems.map(({ id, label }) => (
          <Menu.Item key={id} onClick={() => handleClick(id)}>{label}</Menu.Item>
        ))}
      </Menu>
    </Layout.Header>
  );
};

export default Header;