import React from 'react';
import { Row, Col, Card, Typography, Avatar } from 'antd';

import Skillcard from '../../../../Common/Skillcard';

const { Title, Paragraph } = Typography;

const Skills = ({ id, title, description, skills }) => {
    return (
        <Row id={id} type="flex" justify="center" align="middle" style={{ padding: 8, minHeight: '100vh' }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title level={3} style={{ textAlign: 'center', marginTop: 20 }}>{title}</Title>
          <Paragraph style={{ fontSize: '1rem', textAlign: 'center', color: '#808080'}}>
            {
              description
            }
        </Paragraph>
          
          <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Row type="flex" justify="center" gutter={16}>
              {skills.map((card, index) => (
                <Col>
                  <Skillcard {...card} />
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    );
};

export default Skills