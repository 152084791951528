import React from 'react';
import { Typography, Row, Col, Form, Input, Button } from 'antd';

const { Title } = Typography;

const ContactForm = () => {
  const onFinish = (values) => {
    console.log('Received values of form:', values);
  };

  return (
    <Row id="contact" type="flex" justify="space-around" align="middle" style={{ marginTop: 30, marginBottom: 40, paddingLeft: 10, paddingRight: 10,  minHeight: '100vh' }}>
      <Col xs={24} sm={24} md={22} lg={20} xl={18}>
        <Title level={3} style={{ textAlign: 'center', marginTop: 20 }}>Contact</Title>
        <Form
          name="contact"
          layout="vertical"
          onFinish={onFinish}
          style={{ marginTop: 10, textAlign: 'center' }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter your name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter your email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Message"
            name="message"
            rules={[{ required: true, message: 'Please enter your message!' }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ContactForm