import { Typography, Row, Col  } from 'antd';

import ProjectCard from '../../../../Common/Projectcard';

const { Title } = Typography;

const projects = [
    {
        title: 'Project 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque iaculis mauris, et tempor urna.',
        tags: ['Full Stack', 'DevOps', 'Cloud Architecture', 'Data Analytics', 'AI & ML']
    },
    {
        title: 'Project 2',
        description: 'Maecenas vel velit vel purus vestibulum suscipit. Ut sollicitudin aliquam tempus. Nullam placerat ullamcorper augue, non scelerisque leo tempor sit amet.',
        tags: ['Full Stack', 'Data Analytics', 'AI & ML']
    },
    {
        title: 'Project 3',
        description: 'Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
        tags: ['Cloud Architecture', 'Data Analytics', 'AI & ML']
    }
];

const Projects = () => {
    return (
        <Row id="adventures" type="flex" justify="center" align="middle" style={{ marginTop: 30, minHeight: '100vh' }}>
        <Col xs={24} sm={24} md={22} lg={20} xl={18}>
          <Title level={3} style={{ textAlign: 'center', marginTop: 20 }}>Adventures</Title>
          <div style={{ marginTop: '40px' }}>
            <Row type="flex" gutter={16}>
              {projects.map((card, index) => (
                <Col xs={24} sm={12} md={12} lg={12} xl={8} key={index}>
                  <ProjectCard {...card} />
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    );
};

export default Projects