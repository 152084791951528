import React from 'react';
import { Typography, Row, Col, Avatar, Space, Tag, Divider, Tooltip } from 'antd';
import { MailOutlined, LinkedinOutlined, GlobalOutlined, ReadOutlined, LaptopOutlined, RobotOutlined, MobileOutlined, DatabaseOutlined, VideoCameraOutlined } from '@ant-design/icons';

import Siraj from '../../Assets/Images/siraj.jpg'

const { Title, Text } = Typography;

const Resume = () => {
    return (
        <div style={{ padding: '2rem', maxWidth: '800px', margin: 'auto' }}>
            <Row justify="center">
                <Avatar size={128} src={Siraj} />
            </Row>
            <Row justify="center" style={{ marginTop: '1rem' }}>
                <Title level={1}>Siraj Farhan</Title>
            </Row>
            <Row justify="center">
                <Col xs={24} sm={8} md={8} lg={8} xl={8} style={{ textAlign: 'center' }}>
                    <Text type="secondary">
                        <MailOutlined style={{ color: '#f5222d' }} /> <a href="mailto:hello@siraj.ai">hello@siraj.ai</a>
                    </Text>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8} style={{ textAlign: 'center' }}>
                    <Text type="secondary">
                        <LinkedinOutlined style={{ color: '#0050b3' }} /> <a href="https://www.linkedin.com/in/siraj-farhan/" target="_blank" rel="noreferrer">linkedin.com/in/siraj-farhan/</a>
                    </Text>
                </Col>
            </Row>

            <Divider />
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={16}>
                    <Title level={3}>Profile</Title>
                    <Text>
                        An AI engineer. I design, develop, and scale apps to millions of users. I keep myself updated with the latest AI research straight from the source and use the cutting-edge, state-of-the-art tech for building stuff.
                    </Text>
                    <Divider />
                    <Title level={3}>Professional Experience</Title>
                    <Title level={4}><RobotOutlined style={{ color: '#389e0d' }} /> AI Engineer</Title>
                    <Text>Athena, April 2023 – Present</Text>
                    <Title level={4}><RobotOutlined style={{ color: '#389e0d' }} /> AI Engineer</Title>
                    <Text>JoraAI, November 2022 – April 2023</Text>
                    <Title level={4}><LaptopOutlined style={{ color: '#722ed1' }} /> Full Stack Developer</Title>
                    <Text>RA Sanctuaries, March 2020 – November 2022</Text>
                    <Title level={4}><DatabaseOutlined style={{ color: '#fa8c16' }} /> Data Engineer</Title>
                    <Text>Toko, December 2017 – March 2020</Text>
                    <Title level={4}><LaptopOutlined style={{ color: '#722ed1' }} /> Engineering Lead</Title>
                    <Text>Jamb Inc, May 2017 – December 2017</Text>
                    <Title level={4}><LaptopOutlined style={{ color: '#722ed1' }} /> Full-Stack Developer</Title>
                    <Text>KimeurLabs, March 2017 – August 2017</Text>
                    <Title level={4}><MobileOutlined style={{ color: '#fa541c' }} /> Web and Mobile Developer</Title>
                    <Text>Freelance Consulting, December 2015 – March 2017</Text>
                </Col>
                <Col xs={24} sm={8}>
                    <Title level={3}>Skills</Title>
                    <Space wrap>
                        <Tooltip title="HTML, CSS, JavaScript, ReactJS, Redux, jQuery, AngularJS">
                            <Tag color="blue">Web development</Tag>
                        </Tooltip>
                        <Tooltip title="Android SDK, Java, XML, React Native, JavaScript, ES6/7, Redux">
                            <Tag color="blue">Android development</Tag>
                        </Tooltip>
                        <Tooltip title="React Native, JavaScript, ES6/7, Redux">
                            <Tag color="blue">iOS development</Tag>
                        </Tooltip>
                        <Tooltip title="NodeJS, Django, JavaScript, ES6/7, GraphQL, REST API, MongoDB,  MySQL, BigTable, DataStore">
                            <Tag color="blue">Backend development</Tag>
                        </Tooltip>
                        <Tooltip title="Python, JavaScript, Selenium, Scrapy">
                            <Tag color="blue">Web scraping</Tag>
                        </Tooltip>
                        <Tooltip title="EC2, Lambda, CloudFront, S3, Route53, Compute Engine, BigTable, DataStore, Terraform">
                            <Tag color="blue">Cloud computing</Tag>
                        </Tooltip>
                        <Tooltip title="Python, R, Pandas, BigData, AWS S3, Cluster computing">
                            <Tag color="blue">Data Engineering & Analysis</Tag>
                        </Tooltip>
                        <Tooltip title="Keras, Tensorflow, Natural Language Processing, Reinforcement Learning, Prompt Engineering">
                            <Tag color="blue">Machine Learning</Tag>
                        </Tooltip>
                        <Tooltip title="Git, Jenkins">
                            <Tag color="blue">Misc</Tag>
                        </Tooltip>
                    </Space>
                    <Divider />
                    <Title level={3}>Videos</Title>
                    <Title level={4}><VideoCameraOutlined style={{ color: '#faad14' }} /> Self Introduction <Tag color="blue">1 minute</Tag></Title>
                    <Text>Why do I want to work with LLM? <br /> <a href="https://www.loom.com/share/c12f3302058f40f3bc7dd4e63c9ba11c" target="_blank">Click here to view</a>.</Text>
                    <Title level={4}><VideoCameraOutlined style={{ color: '#faad14' }} /> Demo <Tag color="blue">3 minutes</Tag></Title>
                    <Text>What can I do with LLM? <br/> <a href="https://www.loom.com/share/403359409bd74fcd8fea103e95a60e60" target="_blank">Click here to view</a>.</Text>
                    <Title level={4}><VideoCameraOutlined style={{ color: '#faad14' }} /> Implementation</Title>
                    <Text>How did I do that? <br/> <a href="https://calendly.com/sirajfarhan/30min" target="_blank">Interview me to know ;)</a></Text>
                </Col>
            </Row>
            <Divider />
            <Title level={3}>Education</Title>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                    <Title level={4}><ReadOutlined style={{ color: '#eb2f96' }} /> University of Mysore</Title>
                    <Text>Bachelors in Computer Application</Text>
                    <br />
                    <Text type="secondary">2013 - 2016</Text>
                </Col>
                <Col xs={24} sm={12}>
                    <Title level={4}><ReadOutlined style={{ color: '#eb2f96' }} /> ITMO University</Title>
                    <Text>Masters in Innovation</Text>
                    <br />
                    <Text type="secondary">2020 - 2022</Text>
                </Col>
            </Row>
        </div>
    );
};

export default Resume;
