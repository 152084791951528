import Header from './Common/Header'
import Landing from './Screens/Landing'
import Resume from './Screens/Resume'

const App = () => {
  return <div>
    <Resume />
  </div>
}

export default App;
