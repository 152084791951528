import { Card, Tag } from 'antd';

const { Meta } = Card;

const ProjectCard = ({ title, description, tags }) => {
    return (
        <Card
            style={{ width: '100%', marginBottom: '20px' }}
            cover={
                <img
                    alt={title}
                    src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                />
            }
        >
            <Meta title={title} description={description} />
            <div>
                {tags.map((tag, index) => (
                    <Tag key={index}>{tag}</Tag>
                ))}
            </div>
        </Card>
    );
};

export default ProjectCard;