import React from 'react';
import styled from 'styled-components';
//import { Card, Avatar } from 'antd';

const CardContainer = styled.div`
  width: 180px;
  height: 220px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 32px;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AvatarContainer = styled.div`
  float: left;
  margin-right: 15px;
`;

const Avatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 8px;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #2A2E43;
  margin-top: 8px;
`;

const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #2A2E43;
  margin-top: 0px;
`;

const Skillcard = (props) => {
  return (
    <CardContainer>
      <AvatarContainer>
        <Avatar src={props.icon} />
      </AvatarContainer>
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
    </CardContainer>
  );
};

export default Skillcard;